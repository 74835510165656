import * as React from "react";
import styled, { css } from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import imgDivider from "../assets/images/hero/greenDivider.png";

const StyledImageDivider = styled.div.attrs((props: { isSmall: boolean }) => props)`
  width: 100%;
  ${(props) => {
    switch (props.isSmall) {
      case true:
        return css`
          max-width: 90px;
          margin-bottom: 0px;
        `;
      default:
        return css`
          max-width: 224px;
          margin-bottom: 18px;
        `;
    }
  }};
  height: 25px;
  background: center / contain no-repeat;
  display: inline-block;

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 150px;
  }
`;

interface Props {
  isSmall?: boolean;
  className?: string;
}

const ImageDivider: React.FC<Props> = ({ isSmall }) => {
  return (
    <StyledImageDivider isSmall={isSmall} style={{ backgroundImage: `url("${imgDivider}")` }}></StyledImageDivider>
  );
};

export default ImageDivider;
